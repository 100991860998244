import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BasicMenu = () => {
  // 지금부터 뭐가 변하는 것은 저에게 알려주세요.
  // state 는 어플리케이션의 state 다. (컴포넌트 state 아님)
  // loginSlice 부분이 변경되면 여기서 통제를 받게된다.
  // 어느 곳에서든 아래 코드를 사용하면 로그인 데이터 바뀌는 것을 전부 알 수 가 있다.
  const loginState = useSelector((state) => state.loginSlice);

  //console.log("-----BasicMenu loginState :", loginState);

  return (
    <div className="header">
      <ul className="gnb">
        <li>
          <Link to="/store/product">
            <em className="icon"></em>
            <span>상점</span>
          </Link>
        </li>
        <li>
          <Link to="/rank/all">
            <em className="icon"></em>
            <span>랭킹</span>
          </Link>
        </li>
        <li>
          <Link to="/main/game-list">
            <em className="icon"></em>
            <span>홈</span>
          </Link>
        </li>
        <li>
          <Link to="/support/notice">
            <em className="icon">
              <i className="alarm"></i>
            </em>
            <span>알림</span>
          </Link>
        </li>
        <li>
          <Link to="/setting/default">
            <em className="icon"></em>
            <span>설정</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default BasicMenu;
