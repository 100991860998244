import React, { useEffect, useState } from "react";
import BoardRowComponent from "./BoardRowComponent";
import { getList } from "../../api/boardApi";
import useCustomMove from "../../hooks/useCustomMove";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const initState = {
  dtoList: [],
  pageNumList: [],
  pageRequestDTO: null,
  prev: false,
  next: false,
  totalCount: 0,
  prevPage: 0,
  nextPage: 0,
  totalPage: 0,
  current: 0,
};

const BoardComponent = () => {
  const [serverData, setServerData] = useState(initState);

  //for FetchingModal
  const [fetching, setFetching] = useState(false);

  const boardDiv = "ko";

  const { page, size, div, refresh, moveToList, moveToRead } = useCustomMove();

  useEffect(() => {
    setFetching(true);

    getList({ div, page, size })
      .then((response) => {
        setServerData(response.data);
        setFetching(false);
      })
      .catch((err) => {
        toast.error(err.message, { theme: "dark" });
        setFetching(false);
      });
  }, [div, page, size, refresh]);

  return (
    <>
      <Helmet>
        <title>아하킹 왕게임-역사퀴즈게임-공지</title>
        <meta
          name="description"
          content="아하킹에서 알려드립니다. 공지를 확인해주세요."
        />
        <meta
          property="og:title"
          content="아하킹에서 알려드립니다. 공지를 확인해주세요."
        />
        <meta
          property="og:description"
          content="아하킹에서 알려드립니다. 공지를 확인해주세요."
        />
      </Helmet>
      <ul className="nt-list">
        {serverData.dtoList.length > 0 &&
          serverData.dtoList.map((board) => (
            <BoardRowComponent key={board.boardIdx} data={board} />
          ))}
      </ul>
    </>
  );
};

export default BoardComponent;
