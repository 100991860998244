import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import LoginPage from "../pages/member/LoginPage";

import LoadingComponent from "../components/common/LoadingComponent";
import LogoutPage from "../pages/member/LogoutPage";
import KakaoRedirect from "../pages/member/KakaoRedirectPage";
import GoogleRedirect from "../pages/member/GoogleRedirectPage";
import NaverRedirect from "../pages/member/NaverRedirectPage";
import AppleRedirect from "../pages/member/AppleRedirectPage";

const Loading = LoadingComponent;

const memberRouter = () => {
  return [
    {
      path: "",
      element: <Navigate replace to="login" />,
    },
    {
      path: "login",
      element: (
        <Suspense fallback={<Loading />}>
          <LoginPage />
        </Suspense>
      ),
    },
    {
      path: "logout",
      element: (
        <Suspense fallback={Loading}>
          <LogoutPage />
        </Suspense>
      ),
    },
    {
      path: "kakao",
      element: (
        <Suspense fallback={Loading}>
          <KakaoRedirect />
        </Suspense>
      ),
    },
    {
      path: "google",
      element: (
        <Suspense fallback={Loading}>
          <GoogleRedirect />
        </Suspense>
      ),
    },
    {
      path: "naver",
      element: (
        <Suspense fallback={Loading}>
          <NaverRedirect />
        </Suspense>
      ),
    },
    {
      path: "apple",
      element: (
        <Suspense fallback={Loading}>
          <AppleRedirect />
        </Suspense>
      ),
    },
  ];
};

export default memberRouter;
