import React, { useContext, useState } from "react";

const ModalContext = React.createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [modalData, setModalData] = useState({
    type: "", // '' :  공통, 'SIMPLE' : 간단 모달
    isOpen: false,
    title: "",
    content: "",
    onConfirm: () => {},
    onClose: () => {},
  });

  const openModal = ({
    type,
    isOpen,
    title,
    content,
    content2,
    content3,
    onConfirm,
    onConfirmText,
    onClose,
    onCloseText,
  }) => {
    setModalData({
      type,
      isOpen: true,
      title,
      content,
      content2,
      content3,
      onConfirm,
      onConfirmText,
      onClose,
      onCloseText,
    });
  };

  const closeModal = () => {
    setModalData({
      ...modalData,
      isOpen: false,
    });
    modalData.onClose();
  };

  const confirmModal = () => {
    setModalData({
      ...modalData,
      isOpen: false,
    });
    modalData.onConfirm();
  };

  const renderModalContent = () => {
    switch (modalData.type) {
      case "SIMPLE":
        return (
          <div className="modal-popup">
            <div className="popup confirm">
              <span className="txt">{modalData.content}</span>
              <div className="btn">
                <button
                  className="yes"
                  onClick={() => {
                    if (modalData.onClose) {
                      modalData.onClose();
                    }
                    closeModal();
                  }}
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        );

      case "ADVANCED":
        return (
          modalData.isOpen && (
            <div className="modal-popup" style={styles.overlay}>
              <div className="popup confirm" style={styles.modal}>
                <span className="txt">{modalData.content}</span>
                <span className="txt2">{modalData.content2}</span>
                <span className="txt3">{modalData.content3}</span>
                <div className="btn">
                  <button
                    className="no"
                    onClick={() => {
                      if (closeModal) {
                        closeModal();
                      }
                    }}
                  >
                    {modalData.onCloseText}
                  </button>
                  <button
                    className="yes"
                    onClick={() => {
                      if (confirmModal) {
                        confirmModal();
                      }
                    }}
                  >
                    {modalData.onConfirmText}
                  </button>
                </div>
              </div>
            </div>
          )
        );

      // 새로운 모달 유형 추가 시 case 문을 여기에 추가
      case "NOTICE":
        return (
          modalData.isOpen && (
            <div className="modal-popup nt-popup">
              <div className="popup">
                <div className="roll2">
                  <div className="inner">
                    <div className="txt-box">
                      <div className="nt-tit">
                        <h3>{modalData.title}</h3>
                      </div>
                      <div className="nt-cont">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: modalData.content,
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className="ok-btn"
                      onClick={() => {
                        if (modalData.onClose) {
                          modalData.onClose();
                        }
                        closeModal();
                      }}
                    >
                      확인
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        );

      default:
        return null; // 기본값: 아무 것도 렌더링하지 않음
    }
  };

  return (
    /** SIMPLE : 간단 모달(로그인해), '' : 기본 모달(복잡) */
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {renderModalContent()}
    </ModalContext.Provider>
  );
};

const styles = {
  overlay: {},
  modal: {},
};
