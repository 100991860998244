import React from "react";
import { useModal } from "../common/ModalContext";
import { getCookie } from "../../util/cookieUtil";
import useCustomLogin from "../../hooks/useCustomLogin";
import { useNavigate } from "react-router-dom";
import { decodeHtml } from "../../util/stringUtil";

const GameRowComponent = ({ data }) => {
  //console.log("^^GameRowComponent : ", data);
  const { moveToLogin, moveToPath } = useCustomLogin();
  const navigate = useNavigate();

  const { openModal } = useModal();

  const handleClickGame = async (e) => {
    e.preventDefault();
    // 로그인 체크
    if (!getCookie("member")) {
      modalLogin();
      return;
    }

    if (data.orderCount === 0) {
      modalNotOrder();
      return;
    }
    //navigate({ pathname: "/game/index", search: "?pridx=" + data.periodIdx });
    navigate("/game/index/" + data.periodIdx);
  };

  const modalLogin = () => {
    console.log("-- 로그인 모달 --");
    openModal({
      type: "SIMPLE",
      content: "로그인을 해주세요",
      onClose: () => moveToLogin(),
    });
  };

  const modalNotOrder = () => {
    console.log("-- 매구매 모달 --");

    openModal({
      type: "ADVANCED",
      title: `${data.productName}`,
      content: `${data.productName} 게임을 시작할게요.`,
      content2: "무료 서비스 기간 이에요.",
      content3: "미구매 게임은 사용중 제한이 있을 수 있어요.",
      onConfirmText: "구매 (0원)",
      onCloseText: "그냥할게요",
      onConfirm: () => navigate({ pathname: "/store/product" }),
      onClose: () => navigate({ pathname: "/game/index/" + data.periodIdx }),
    });
  };

  return (
    <a href="#" onClick={handleClickGame} className="map">
      <div className={`card ${data.memberIdx === null && "screen"}`}>
        <div className="g-tit">
          <h4>{data.productName}</h4>
        </div>
        <div
          className={`g-thum ${
            data.memberIdx === null ? "thum-lock" : "thum-unloc"
          }`}
        >
          <img
            src={`${process.env.REACT_APP_CONTENTS_SERVER_HOST}/img/period/${data.code}-unlock.jpg`}
            alt=""
          />
        </div>
        <div className="g-info">
          <div className="l-box">
            <p>
              {data.periodName} {/* <br /> (1 ~ {data.kingCount}) */}
            </p>
          </div>
          <div className="r-box">
            <p>
              {data.yearFrom} <br /> ~
              {data.yearTo === "NOW"
                ? new Date().getFullYear().toString()
                : data.yearTo}
            </p>
          </div>
        </div>
      </div>
      {data.memberIdx === null && (
        <div className="close">
          <div className="lock"></div>
        </div>
      )}
    </a>
  );
};

export default GameRowComponent;
