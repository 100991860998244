// 인가 요청 URL  ==> https://appleid.apple.com/auth/authorize?client_id=com.ahaking.web&redirect_uri=http://localhost:4000/member/apple&response_type=code&response_mode=form_post&state=YOUR_STATE&scope=email%20name
import axios from "axios";
import { API_SERVER_HOST } from "./apiConfig";

import { v4 as uuidv4 } from "uuid";

const state_code = uuidv4();

// Identifier
const rest_api_key = "com.ahaking.web";

const rest_api_secret = process.env.REACT_APP_APPLE_REST_API_KEY;

const redirect_uri = process.env.REACT_APP_APPLE_REDIRECT_URI;

// 애플 인가 코드 요청 URL
const auth_code_path = process.env.REACT_APP_APPLE_AUTH_CODE_PATH;

// 애플 엑세스 엑세스 토큰 요청 URL (Access Token)
const access_token_url = process.env.REACT_APP_APPLE_ACCESS_TOKEN_URL;

// 애플 엑세스 토큰 서버로 요청 URL (Access Token)
const server_access_token_url = `${API_SERVER_HOST}/api/member/auth/apple`; // /apple-token

// 애플 로그인 링크를 동적으로 생성
export const getAppleLoginLink = () => {
  const appleURL = `${auth_code_path}?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code id_token&response_mode=form_post&scope=email name`;

  return appleURL;
};

// !!!!! [애플]는 엑세스 토큰을 서버에서 발급 받기 때문에 이 코드는 사용할 수 없다.
// [애플] 엑세스 토큰 요청하는 함수 (파라미터는 애플에서 받은 인가코드다)
// 이 단계까지 진행되어야 마지막 단계인 액세스 토큰을 가지고 사용자 정보를 얻어오는 단계로 넘어갈 수 있다.
// export const getAccessToken = async (authCode) => {
//   // 헤더 설정
//   const header = {
//     headers: {
//       "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
//     },
//   };

//   // 애플 Client Secret 사용안함 (만일 사용할 경우 파라미터에 client_secret 추가)
//   // 애플 엑세스 토큰 만료시간은 1시간.
//   // 그래서 리프래쉬 토큰과 함께 사용하지 않으면 쓸모가 없다.
//   const params = {
//     grant_type: "authorization_code",
//     client_id: rest_api_key,
//     client_secret: rest_api_secret,
//     redirect_uri: redirect_uri,
//     code: authCode,
//   };

//   const res = await axios.post(access_token_url, params, header);

//   const accessToken = res.data.access_token;

//   console.log("애플 토큰정보(res.data) : ", res.data);

//   return accessToken;
// };

// !!!!! 엑세스토큰, 회원정보 모두 서버에서 처리한다. [애플, 네이버]
// 서버를 통해서 애플의 Access Token 을 가져와 서버에서 다시 애플의 회원정보를 가져온다.
// 최종 결과는 회원정보다.
// API : api.com/api/member/auth/apple
export const getServerMemberWithAccessToken = async (authCode) => {
  const params = {
    authCode: authCode,
    state: state_code,
  };

  const res = await axios.get(server_access_token_url, { params });

  console.log("애플 회원정보(res.data) : ", res.data);

  return res.data;
};

// 서버에서 Apple 사용자 정보 가져온다. (Apple 에서 발급 받은 토큰 사용)
export const getMemberWithAccessToken = async (accessToken) => {
  const res = await axios.get(
    `${API_SERVER_HOST}/api/member/auth/apple?accessToken=${accessToken}`
  );
  return res.data;
};
