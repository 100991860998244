import React, { useEffect, useMemo, useState } from "react";

import { API_SERVER_HOST } from "../../api/apiConfig";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import useCustomLogin from "../../hooks/useCustomLogin";
import useCustomMember from "../../hooks/useCustomMember";
import { useLocation } from "react-router-dom";

const QuickComponent = () => {
  //console.log("-----QuickComponent");

  const [photo, setPhoto] = useState("/img/00-icon-profile.png");

  const { isLogin, loginState } = useCustomLogin();

  const memberState = useSelector((state) => state.memberSlice);

  const { refreshMyInfo } = useCustomMember();

  const location = useLocation();
  const currentPath = location.pathname;

  //console.log("^^currentPath : ", currentPath);

  const host = API_SERVER_HOST;

  useEffect(() => {
    // console.log(
    //   "^^useEffect-",
    //   memberState.status,
    //   "#",
    //   loginState.memberId,
    //   "#",
    //   currentPath
    // );
    if (currentPath !== "/member/login") {
      if (memberState.status === "idle" && loginState.memberId) {
        const param = { memberId: loginState.memberId };
        refreshMyInfo(param);
      }
    }
  }, [refreshMyInfo, memberState.status, loginState.memberId, currentPath]);

  useEffect(() => {
    //console.log("^^QuickComponent2 - useEffect2:", memberState);
    if (memberState.member) {
      setPhoto(`${host}/api/file/member/${memberState.member.photo}`);
    }
  }, [memberState.member]);

  if (currentPath === "/member/login") {
    return null;
  }

  return (
    <>
      <div className="qk-btn">
        <Link to="/my/profile" className="mypage-btn">
          <img src={`${photo}`} alt="myphoto" />
        </Link>
      </div>
    </>
  );
};

export default QuickComponent;
