import React, { useEffect, useRef, useState } from "react";
import { numberWithCommas, truncateDecimal } from "../../util/numberUtil";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const GameDashboard = () => {
  //console.log("-----GameDashboard");

  const [img, setImg] = useState("");
  const [isLog, setIsLog] = useState(false);
  const [orgHeart, setOrgHeart] = useState(0);

  const gameSetState = useSelector((state) => state.gameSetSlice, shallowEqual);

  const gameControlState = useSelector(
    (state) => state.gameControlSlice,
    shallowEqual
  );

  const lastExecutedTime = useRef(0);

  // 게임설정 하트 고정시킴 (빨강하트)
  useEffect(() => {
    setOrgHeart(gameSetState.heart);
  }, []);

  useEffect(() => {
    // const now = Date.now();
    // if (now - lastExecutedTime.current < 1000) {
    //   // 300ms 이내에 실행된 적이 있으면 무시
    //   return;
    // }
    // lastExecutedTime.current = now;

    //console.log("^^GameDashboard-face:", gameControlState.isToglled);

    const basicFace = "/img/face/basic.jpg";

    let newImgPath;
    if (gameControlState.isCorrect === null) {
      newImgPath = basicFace;
    } else if (gameControlState.isCorrect) {
      newImgPath = "/img/face/smile.jpg";
    } else {
      newImgPath = "/img/face/sad.jpg";
    }

    setImg(newImgPath);
    const timer = setTimeout(() => {
      setImg(basicFace);
    }, 2000);
    return () => clearTimeout(timer);
  }, [gameControlState.isToglled]);

  const dispatch = useDispatch();

  //const [elapsedTime, setElapsedTime] = useState(0); // 경과 시간을 초 단위로 저장

  // console.log("^^gameState.status : ", gameState.status);
  // console.log("^^gameState.error : ", gameState.error);
  // console.log("^^gameState : ", gameState);

  // useEffect(() => {
  //   const startTime = Date.now();

  //   const intervalId = setInterval(() => {
  //     const currentTime = Date.now();
  //     setElapsedTime(Math.floor((currentTime - startTime) / 1000)); // 경과 시간을 초 단위로 계산
  //   }, 1000);

  //   return () => clearInterval(intervalId); // 컴포넌트가 언마운트될 때 interval을 정리
  // }, []);

  // // 초를 HH:mm:ss 형식으로 변환하는 함수
  // const formatTime = (timeInSeconds) => {
  //   const hours = String(Math.floor(timeInSeconds / 3600)).padStart(2, "0");
  //   const minutes = String(Math.floor((timeInSeconds % 3600) / 60)).padStart(
  //     2,
  //     "0"
  //   );
  //   const seconds = String(timeInSeconds % 60).padStart(2, "0");
  //   return `${hours}:${minutes}:${seconds}`;
  // };

  const timestamp = gameControlState.gcAtEnd - gameControlState.gcAtStart; // 예시 타임스탬프
  const totalSeconds = Math.floor(timestamp / 1000);
  const seconds = totalSeconds % 60;

  return (
    <div className="top">
      <div className="game-info">
        <div className="line line1">
          <div className="progress">
            <div className="pg-ber">
              <div
                className={`ber w${truncateDecimal(
                  gameControlState.progress,
                  0
                )}`}
              ></div>
            </div>
            <b>{truncateDecimal(gameControlState.progress, 1)}%</b>
          </div>
          <div className="time">
            {/* <p>{formatTime(elapsedTime)}</p> */}
            {/* <p>00:00</p> */}
          </div>
        </div>
        <div className="line line2">
          <div className="king-box">
            <div className="user-box">
              <div className="outline">
                <div className="life">
                  <em className="heart"></em>
                  <b>{gameControlState.heart}</b>
                </div>
              </div>
              <div className="point">
                <em></em>
                <p>{numberWithCommas(gameControlState.totalPoint)}</p>
              </div>
            </div>

            <div className="k-photo">
              <img src={img} alt="" />
            </div>
            <div className="k-info">
              <div className="sound" onClick={() => setIsLog(true)}>
                <em></em>
              </div>
              <div className="score-wrapper">
                <div className="icon-wrapper">
                  <i className="point">P</i>
                </div>
                <div className="point-bar">
                  <div className="bg">
                    <p className="percentage">
                      {numberWithCommas(gameControlState.gcPoint)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isLog && (
          <div
            style={{
              backgroundColor: "white",
              fontSize: "13px",
              position: "fixed",
              top: 30,
              left: 0,
              width: "100%",
              lineHeight: 1.5,
              padding: "4px",
              zIndex: 1000,
            }}
            onClick={() => setIsLog(false)}
          >
            [{gameControlState.isToglled?.toString()}] *[
            {gameControlState.heart}] [door:
            {gameControlState.isOpenDoor?.toString()}] [
            <b>
              {gameControlState.gameCount}-{gameControlState.optionCount}
            </b>
            ] [ corr:
            {gameControlState.isCorrect?.toString()}] [ cho:
            {gameControlState.isChoice?.toString()}]
            <br />
            {/* [idxs:{JSON.stringify(gameControlState.gcIdx)}]  */}
            [idx:{gameControlState.gcIdx}] [p:
            {gameControlState.gcPoint}] [ans:
            {gameControlState.answer}] [{gameControlState.gcAtStart}-
            {gameControlState.gcAtEnd}={Math.floor(seconds)}]
          </div>
        )}
      </div>
    </div>
  );
};

export default GameDashboard;
