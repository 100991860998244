import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import useCustomLogin from "../../hooks/useCustomLogin";
import {
  getAccessToken,
  getMemberWithAccessToken,
  getServerMemberWithAccessToken,
} from "../../api/appleApi"; // apple API 사용
import { useDispatch } from "react-redux";
import { login } from "../../redux/slices/loginSlice";

const AppleRedirectPage = () => {
  const [searchParams] = useSearchParams();

  const { moveToPath } = useCustomLogin();

  const dispatch = useDispatch();

  // URL에서 클라이언트로 전달된 memberInfo를 추출
  const memberInfo = JSON.parse(
    decodeURIComponent(searchParams.get("memberInfo"))
  );

  useEffect(() => {
    if (memberInfo) {
      // 받아온 회원 정보로 로그인 처리
      dispatch(login(memberInfo));
      // 로그인 후 이동할 페이지 설정
      moveToPath(memberInfo.social ? "/my/profile" : "/");
    }
  }, []);

  /*
  useEffect(() => {
    // 애플에서 받은 인가 코드를 이용해 다시 애플의 엑세스 토큰을 받아서 다시 회원정보를 가져옴. (서버에서 모두 처리함)
    getServerMemberWithAccessToken(authCode).then((memberInfo) => {
      console.log("^^ memberInfo : ", memberInfo);

      dispatch(login(memberInfo));

      //소셜 회원이 아니라면
      if (memberInfo && !memberInfo.social) {
        moveToPath("/");
      } else {
        moveToPath("/my/profile");
      }
    });
  }, [authCode]);
*/
  return (
    <div className="modal-popup loading">
      <div className="sword-img"></div>
      <ul className="loading-ber">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
};

export default AppleRedirectPage;
