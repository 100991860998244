import React from "react";

const LoadingComponentMinimal = () => (
  <div
    style={{
      width: "100%",
      height: "100%",
      position: "absolute",
      zIndex: "999",
      textAlign: "center",
      marginTop: "20px",
      fontSize: "18px",
      color: "#888",
    }}
  >
    Loading...
  </div>
);

export default LoadingComponentMinimal;
