import React, { useEffect, useState } from "react";
import BasicLayout from "../../layout/BasicLayout";

const VoicePage = () => {
  const [text, setText] = useState("");

  const speakText = () => {
    if (!text) return;

    const speech = new SpeechSynthesisUtterance(text);
    speech.lang = "ko-KR"; // 한국어 설정
    speech.rate = 1.0; // 음성 속도
    speech.pitch = 1.0; // 음성 높낮이
    window.speechSynthesis.speak(speech);
  };

  useEffect(() => {
    speakText("안녕하세요222, 이 글을 음성으로 변환합니다.");
  }, []);

  return (
    <BasicLayout pageClassName="setting bg-map">
      <div style={styles.default}>
        <div>
          <h2>🗣️ 텍스트를 음성으로 변환</h2>
          <textarea
            rows="4"
            cols="50"
            placeholder="여기에 텍스트를 입력하세요..."
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <br />
          <button onClick={speakText}>🎤 음성 출력</button>
        </div>
      </div>

      <div style={styles.default}></div>

      <div style={{ margin: 30, padding: 30 }}></div>
    </BasicLayout>
  );
};

const styles = {
  default: {
    margin: "20px",
    padding: "20px",
    border: "1px solid #000",
  },
  modal: {},
};

export default VoicePage;
