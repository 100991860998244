import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMyInfo } from "../../api/myApi";

// 주의!!! 비동기 함수라도 여기서 async ~ await 명시적으로 넣어주어야 한다???
export const getMyInfoAsync = createAsyncThunk(
  "getMyInfoAsync",
  async (param) => {
    //console.log("^^new3-getMyInfoAsync param : ", param);

    try {
      const response = await getMyInfo(param); // 리턴하는 데이터가 payload 다. 초기값 형태로 만든다. ==> const initState = []
      // Only return serializable data
      return {
        data: response.data, // Example: Assuming response.data is serializable
      };
    } catch (error) {
      throw error;
    }
  }
);

const initState = {
  member: null,
  status: "idle",
  error: null,
};

const memberSlice = createSlice({
  name: "memberSlice",
  initialState: initState,
  reducers: {
    // 다른 리듀서들
    resetMemberState: (state) => {
      return initState; // 상태를 초기값으로 되돌림
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyInfoAsync.pending, (state) => {
        return {
          ...state,
          status: "loading",
        };
      })
      .addCase(getMyInfoAsync.fulfilled, (state, action) => {
        // getMyInfoAsync 에서 리턴한 데이터가 action.playload.data 가 된다. ==> return [];
        //console.log("getMyInfoAsync fullfilled: ", state, action.payload.data);
        return {
          ...state,
          status: "succeeded",
          member: action.payload.data, // Action payload is used directly as the new state
        };
      })
      .addCase(getMyInfoAsync.rejected, (state, action) => {
        console.log("getMyInfoAsync rejected : ", state, action);
        return {
          ...state,
          status: "failed",
          error: action.error.message,
        };
      });
  },
});

export const { resetMemberState } = memberSlice.actions;
export default memberSlice.reducer;
