import React from "react";
import { TypingProvider } from "../components/game/TypingContext";

const GameLayout = ({ children, pageClassName }) => {
  return (
    <TypingProvider>
      <div className={`container ${pageClassName}`}>{children}</div>
    </TypingProvider>
  );
};

export default GameLayout;
