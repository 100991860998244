import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, Navigate, useNavigate } from "react-router-dom";
import { login, loginPostAsync, logout } from "../redux/slices/loginSlice"; // loginPostAsync ==> 비동기 로그인
import { getCookie } from "../util/cookieUtil";
import { useCallback } from "react";

const useCustomLogin = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // state 는 전체 상태, state.loginSlice 는 loginSlice 리듀서가 관리하는 상태 (initState)
  const loginState = useSelector((state) => state.loginSlice); // 로그인 상태

  //console.log("^^loginState.memberId : ", loginState.memberId);

  const isLogin = loginState.memberId ? true : false; // 로그인 여부

  //console.log("isLogin value:", isLogin); // 로그로 isLogin 값 확인

  /**
   * 현재 쿠키를 확인하여 로그인 상태를 새로 고침
   * 쿠키 정보 있으면 로그인, 쿠키 만료시 로그아웃
   */
  const refreshLoginState = useCallback(() => {
    const memberInfo = getCookie("member");

    //console.log("^^refreshLoginState-getCookie : ", memberInfo);

    if (memberInfo) {
      if (memberInfo.nickname) {
        memberInfo.nickname = decodeURIComponent(memberInfo.nickname);
      }

      // console.log(
      //   "^^refreshLoginState : ",
      //   loginState.memberId,
      //   "#",
      //   memberInfo.memberId
      // );

      if (loginState.memberId !== memberInfo.memberId) {
        dispatch(login(memberInfo));
      }
    } else {
      dispatch(logout());
    }
  }, [dispatch, loginState.memberId]);

  const doLogin = async (loginParam) => {
    // 로그인 함수
    const action = await dispatch(loginPostAsync(loginParam)); // createAsyncThunk 이용.
    return action.payload; // API 결과 데이터(state or error)
  };

  const doLogout = () => {
    // login,logout 은 모두 reducer 에서의 액션 생성자다.
    // 액션 생성자는 함수에 괄호가 있어야한다.
    // 괄호가 없으면 일반 함수이기 때문에 dispatch 가 액션 생성자로 인식을 하지 못하기 때문에 제대로 동작하지 않는다.
    dispatch(logout());
  };

  const moveToPath = (path) => {
    // 로그인 관련해서 페이지 이동
    navigate({ pathname: path }, { replace: true });
  };

  //^^ location.href
  const moveToLogin = () => {
    // 로그인 페이지로 이동, 사용 :> onClick={moveToLogin}
    // 현재 페이지를 새 페이지로 교체
    // 버튼 틀릭 시 로그인 페이지로 이동할 때 사용.
    // 브라우저의 기록(history) 스택을 덮어씀(뒤로가기 버튼 눌렀을 때 이전페이지로 못감)
    navigate({ pathname: "/member/login" }, { replace: true });
  };

  //^^ <a href>
  const moveToLoginReturn = () => {
    console.log("moveToLoginReturn ----"); // 디버깅 로그 추가

    // 로그인 페이지로 이동 컴포넌트, 사용 :> return moveToLoginReturn();
    // 렌더링 시 즉시 다른 경로로 이동 (Redirect 와 동일)
    // 컴포넌트 렌더링 중 조건에 따라 페이지 이동이 필요할 때 적합
    return <Navigate replace to="/member/login" />;
  };

  const exceptionHandle = (ex) => {
    console.log("Exception --------------------");

    console.log(ex);

    const errorMsg = ex;
    const errorStr = createSearchParams({ error: errorMsg }).toString();

    if (errorMsg === "REQUIRE_LOGIN") {
      alert("로그인 해야만 합니다.");
      navigate({ pathname: "/member/login", search: errorStr });

      return;
    }

    if (ex.response.data.error === "ERROR_ACCESSDENIED") {
      alert("해당 메뉴를 사용할 수 있는 권한이 없습니다.");
      navigate({ pathname: "/member/login", search: errorStr });
      return;
    }
  };

  return {
    loginState,
    isLogin,
    doLogin,
    doLogout,
    moveToPath,
    moveToLogin,
    moveToLoginReturn,
    refreshLoginState,
    exceptionHandle,
  };
};

export default useCustomLogin;
