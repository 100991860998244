import React, { Children, createContext, useContext, useRef } from "react";

const TypingContext = createContext();

export const useTyping = () => useContext(TypingContext);

export const TypingProvider = ({ children }) => {
  console.log("^^TypingContext");

  const isTypingRef = useRef(false);

  return (
    <TypingContext.Provider value={isTypingRef}>
      {children}
    </TypingContext.Provider>
  );
};
